import PropTypes from 'prop-types'
import {graphql} from 'gatsby'
import styled, {css} from 'styled-components'
import {isEmpty} from 'lodash-es'
import {getLangToSlug, media, normalizeId} from '../utils'
import {AppShell, Box, Button, Hero, ProductsContainer, SectionMaterials} from '../components/custom'
import useMediaTier from '../hooks/useMediaTier'


const StyledBedBasesWrapper = styled.div`
  display: block;

  > :first-child {
    padding-top: 9.375rem;
  }
`

const StyledBedBaseBox = styled(Box)`
  align-items: center;
`

const StyledBedBaseBoxFooter = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 3.375rem;

  ${media.down('mobile')(css`
    &:last-child {
      justify-self: center;
    }
  `)}

  ${media.down('mobile')(css`
    margin-top: 1.25rem;
  `)}
`

const StyledMaterialButton = styled(Button)`
  &:not(:first-child) {
    margin-left: 1.5rem;
  }
`

const BedBases = ({data, pageContext: {lang}}) => {
  const {mediaMatches: isMobile} = useMediaTier('mobile')
  const {title, featuredImage, seo, translations, uri, template: {bed_bases: {
    selectedBedBases, bedBaseMaterials, allMaterialsButton, catalogueLink,
  }}} = data.wpPage

  const isOdd = (number) => {
    return number % 2 === 1
  }

  return (
    <AppShell title={title} seo={seo} lang={lang} langToSlug={getLangToSlug({translations, uri, lang})}>
      <Hero
          url={featuredImage?.node?.localFile?.url}
          title={title}
          buttonTitle={catalogueLink?.title}
          to={catalogueLink?.url}
      />
      <If condition={!isEmpty(selectedBedBases)}>
        <StyledBedBasesWrapper>
          <For each="bedBase" of={selectedBedBases} index="idx">
            <If condition={bedBase.bedBase}>
              <ProductsContainer id={normalizeId(bedBase?.bedBase.title)} key={idx} inverted={isOdd(idx)}>
                <StyledBedBaseBox
                    reversed={isOdd(idx) && !isMobile}
                    title={bedBase?.bedBase.title}
                    description={bedBase?.bedBase.content}
                    image={bedBase.bedBase.featuredImage?.node}
                    footer={
                      <StyledBedBaseBoxFooter>
                        <If condition={
                          !isEmpty(bedBase.bedBase?.bed_base.eshopLink?.url) &&
                        !isEmpty(bedBase.bedBase?.bed_base.eshopLink?.title)}
                        >
                          <Button
                              inverted={isOdd(idx)}
                              to={bedBase.bedBase?.bed_base.eshopLink?.url}
                          >
                            {bedBase.bedBase?.bed_base.eshopLink?.title}
                          </Button>
                        </If>
                        <If condition={
                          !isEmpty(bedBase.bedBase?.bed_base.buttonMaterial?.url) &&
                        !isEmpty(bedBase.bedBase?.bed_base.buttonMaterial?.title)}
                        >
                          <StyledMaterialButton
                              type="text"
                              inverted={isOdd(idx)}
                              to={bedBase.bedBase?.bed_base.buttonMaterial?.url}
                          >
                            {bedBase.bedBase?.bed_base.buttonMaterial?.title}
                          </StyledMaterialButton>
                        </If>
                      </StyledBedBaseBoxFooter>
                    }
                />
              </ProductsContainer>
            </If>
          </For>
        </StyledBedBasesWrapper>
      </If>
      <If condition={!isEmpty(bedBaseMaterials)}>
        <SectionMaterials materials={bedBaseMaterials} allMaterialsButton={allMaterialsButton} />
      </If>
    </AppShell>
  )
}

export const data = graphql`
 query BedBasesPage($id: String!) {
    wpPage(id: { eq: $id }) {
       title
       slug
       translations {
         href
         locale
       }
       uri
       seo {
        metaDesc
        opengraphDescription
        opengraphSiteName
        opengraphTitle
        opengraphType
        opengraphUrl
        title
        twitterDescription
        twitterTitle
        opengraphImage {
          mediaItemUrl
        }
        twitterImage {
          mediaItemUrl
        }
      }
       featuredImage {
        node {
          localFile {
            url
          }
        }
      }
      template {
        ... on WpTemplate_Rosty {
          bed_bases {
            catalogueLink {
              title
              url
            }
            selectedBedBases {
              bedBase {
                ... on WpBed_base {
                  title
                  content
                  featuredImage {
                    node {
                      altText
                      srcSet
                    }
                  }
                  bed_base {
                    eshopLink {
                      title
                      url
                    }
                    buttonMaterial {
                      title
                      url
                    }
                    material {
                      ... on WpMaterial {
                        title
                      }
                    }
                  }
                }
              }
            }
            bedBaseMaterials {
              material {
                ... on WpMaterial {
                  title
                  materials {
                    referenceImage {
                      altText
                      srcSet
                    }
                  }
                }
              }
            }
            allMaterialsButton {
              title
              url
            }
          }
        }
      }
    }
  }
`

BedBases.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object,
}

export default BedBases
